import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { navigate } from 'gatsby'
import { Button, Image, Row, Col } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import cautionImg from "../images/trackday/caution.svg"
import everywhereImg from "../images/trackday/everywhere.svg"
import communityImg from "../images/trackday/community.svg"
import registerImg from "../images/trackday/register.svg"
import screensImg from "../images/trackday/screens.png"
import dskImg from "../images/trackday/dsk_logo.png"

const TrackDayPage = () => (
  <Layout pageInfo={{ pageName: "trackday" }}>
    <Seo title="TrackSecure für TrackDay Veranstalter" keywords={[`Veranstalter`, `Trackday`, `fahren`, `safety`, `Auto`, `Tracktool`, `Fahrsicherheit`, `Sicherheit`, `Mobilität`, `TrackSecure`, `App`]} />
    <h1 class="heading-4">Mit Sicherheit, mehr Fahrspaß!</h1>
    <Row>
      <Col>
        <div class="m-3">
          Wir sichern euren TrackDay ab!
        </div>
      </Col>
    </Row>
    <Row>
      <Col>
        <div class="m-3">
          Mehr Sicherheit führt effektiv zu mehr Zeit für jeden Teilnehmer auf der Strecke.
          Durch das eigenständige Erkennen von stehengebliebenen Autos und Unfällen erhöht TrackSecure die Verkehrssicherheit zusätzlich.
        </div>
      </Col>
    </Row>
    <Row>
      <Col>
        <div class="m-3">
          <b><i>DRIVE. SAFE. TOGETHER.</i></b> ist nicht nur unsere Firmenphilosophie, sondern auch der Grundpfeiler unserer App.
          Als Community könnt ihr und eure Fahrer gemeinsam euren Trackday sicherer gestalten.
          Das TrackSecure Frühwarnsystem für Trackday Anbieter ist einzigartig.
        </div>
      </Col>
    </Row>
    <Row>
      <Col>
        <div class="m-3">
          Das Smartphone des Teilnehmers wird durch unsere App zum Digitalen Hochleistungscockpit und bringt die Sicherheitsfeatures professioneller Rennserien zu euren Fahrern ins Auto. Auch unter Berücksichtigung der Rennstrecken mit schwierigen Topologien. Das System informiert die Fahrenden über verschiedene Warnungen wie Unfälle, Öl- und Betriebsmittel Spuren, Wettereinflüsse, sowie andere bevorstehende Gefahren auf der Rennstrecke.
          Es warnt sie aktiv wenn sie sich Gefahrenstellen nähern, sodass jeder Fahrende präventiv darauf reagieren kann.
        </div>
      </Col>
    </Row>
    <Row>
      <Col>
        <div class="m-3">
          <div class="trackday-icon"><Image style={style.images} src={cautionImg} /></div>
          <div class="trackday-feature">Erhöhte Verkehrssicherheit auf Rennstrecken</div>
          <div >Durch das TrackSecure System können alle Teilnehmer des Events Incidents melden und zur erhöhten Sicherheit beitragen: Veranstalter, Marshals, Fahrer, Beifahrer und Fotografen.</div><br />
        </div>
      </Col>
      <Col>
        <div class="m-3">
          <div class="trackday-icon"><Image style={style.images} src={everywhereImg} /></div>
          <div class="trackday-feature">Funktioniert in ganz Europa</div>
          <div >Wir sichern auch Strecken mit schwierigen Topologien, so wie temporäre Renn- und Rallystrecken in ganz Europa ab. Dafür sind keine zusätzlichen Installationen an der Rennstrecke nötig. Nach Absprache ist das System auch weltweit einsatzbereit.</div><br />
        </div>
      </Col>
    </Row>
    <Row>
      <Col>
        <div class="m-3">
          <div class="trackday-icon"><Image style={style.images} src={communityImg} /></div>
          <div class="trackday-feature">Mitglied der Community werden</div>
          <div >Dein Event ist für über 13000 potenzielle Teilnehmer in unserem Eventkalender sichtbar. Durch das Listing deines Events kann die Community die Sicherheitsfeatures nutzen. Ohne zusätzlichen Aufwand für dich.</div><br />
        </div>
      </Col>
      <Col>
        <div class="m-3">
          <div class="trackday-icon"> <Image style={style.images} src={registerImg} /></div>
          <div class="trackday-feature">Einfache Registrierung für Teilnehmer</div>
          <div >TrackSecure macht das Smartphone des Teilnehmers zu einem hochperformanten Strecken-Dashboard, so dass keine zusätzliche Hardware nötig ist.
            Das On-boarding dauert maximal 5 Minuten: Download in den Stores, Installation und Registrierung.
          </div>
        </div>
      </Col>
    </Row>
      <Row className="trackday p-3">
        <Col>
          <div style={style.dsktext}>
            <div>
              <p class="trackday-headline"><b><i>Unsere Basisfeatures für dich</i></b></p>
              Wir bieten dir durch den Einsatz von TrackSecure kostenlosen Sicherheitszuwachs durch die Community Nutzung.
              Wir stellen dir deinen Trackday kostenlos in unserer App als Event ein.
              Du profitierst von der Reichweite von mehr als 13.000 aktiven Fahrern auf unserer Plattform.
            </div>
          </div>
        </Col>
        <Col>
          <Image style={style.screens} src={screensImg} />
        </Col>
      </Row>
    <Row>
      <Col>
        <div class="m-3">
          <p class="trackday-headline"><b><i>Was wir dir zusätzlich bieten können?</i></b></p>
        </div>
      </Col>
    </Row>
    <Row>
      <Col>
        <div class="m-3">
          Durch unser <b>Live Monitoring</b> hast du als Veranstalter, alles im Überblick und kannst jederzeit ins geschehen eingreifen oder den Teilnehmer Individuell kontaktieren.
        </div>
      </Col>
    </Row>
    <Row>
      <Col>
        <div class="m-3">
          Mit unserem System kannst du die <b>Akkreditierung</b> leichter abwickeln. Durch den direkten Kontakt zu den Teilnehmern finden verliehene und mitgenommene Transponder schnell ihren Weg zu dir zurück. Wir können deinen Trackday mit dir zusammen digitalisieren und viele kleine Lasten von deinen Schultern nehmen.
        </div>
      </Col>
    </Row>
    <Row>
      <Col>
        <div class="m-3">
          <b>Individuelle Kontaktmöglichkeiten</b> zu allen akkreditierten Teilnehmern.
          Wir bieten dir die Möglichkeit jeden Teilnehmer benachrichtigen zu können:
          Strecke Auf/Zu, Push Notifications, Individuelle-(Private) Nachrichten
        </div>
      </Col>
    </Row>
    <Row>
      <Col>
        <div class="m-3">
          Unser <b>Pre-Paid/Payback Programm</b> für User von TrackSecure.
          Du denkst für deine Teilnehmer auch einen Schritt weiter? Mit den Petrols bieten wir dir einzigartige Möglichkeiten deinen Kunden etwas zurückzugeben.
        </div>
      </Col>
    </Row>
    <Row>
      <Col>
        <div class="m-3">
          <b>Buchung des Events direkt in der App</b><br />
          Rundum Betreuung<br />
          Organisation und Vor-Ort-Betreuung<br />
          Statistiken zum Event
        </div>
      </Col>
    </Row>
      <Row className="trackday p-3">
        <Col>
          <div class="col-12 mb-3" style={style.dsktext}>
            In <b><i>KOOPERATION</i></b> mit dem Deutschen Sportfahrer Kreis setzen wir TrackSecure
            bereits bei folgenden TrackDays ein:
            <br /><br />
            Spa, Zandvoort, Hockenheimring, Bilster Berg, Meppen, Nürburgring GP/Nordschleife, Circuit de Chambley
          </div>
        </Col>
        <Col>
          <div>
            <Image style={style.dsk} src={dskImg} />
          </div>
        </Col>
      </Row>
    <Row>
      <Col>
        <div class="m-3">
          <p class="trackday-headline"><b>Jeder TrackDay ist anders - </b></p>
          Wir machen dir ein individuelles Angebot
        </div>
      </Col>
      <Col>
        <div class="m-3">

          <Button variant="dark" onClick={() => navigate('/contact')}>ANFRAGEN</Button>
        </div>
      </Col>
    </Row>

  </Layout>
)

let style = {
  images: {
    height: 50
  },
  screens: {
    height: 400,
    margin: 20
  },
  dsk: {
    height: 100,
    marginBottom: 80,
    marginTop: 100
  },
  dsktext: {
    marginTop: 100
  }
}

export default TrackDayPage
